<template>
  <div>
    <img v-if="$vuetify.breakpoint.xsOnly" :src="imgMob" style="width: 100%" />
    <img v-else :src="img" style="width: 100%" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/image/student-profile.png"),
      imgMob: require("@/assets/imageMob/student-profile.png"),
    };
  },
};
</script>
